import picOne from "../../images/temppeli.jpg";

export const infoData = {
    id: "about",
    $lightbg: true,
    $lighttext: true,
    $lighttextDesc: true,
    topline: "(ノಠ益ಠ)ノ彡┻━┻",
    description: "",
    headline:
        "Vuonna 2019 päätin haastaa itseni osallistumalla avoimeen Java-kurssiin ja uppouduin täysin koodaamisen pariin. Tästä innostukseni jatkui web-kehityksen maailmaan. Nykyään keskityn pääasiassa verkkosivujen ja verkkokauppojen kehittämiseen. Vapaa-ajallani olen myös rakentanut omia näppäimistöjä ja tietokoneita sekä asentanut niihin räätälöityjä laiteohjelmistoja. Kun en ole tietokoneen ääressä, maastopyöräilen, laskettelen, valokuvaan ja nautin ruoanlaitosta sekä vietän aikaa läheisteni seurassa.",
    descriptionTwo: "",
    $imgstart: true,
    img: `${picOne}`,
    alt: "temppeli",
    dark: false,
    primary: false,
    darkText: true,
};
