import picCharity from "../../images/joel-muniz-unsplash.jpg";
import picIllustrator from "../../images/boston-unsplash.jpg";
import picLaw from "../../images/dariusz-sankowski-unsplash.jpg";
import picLily from "../../images/lily.jpg";

export const projectsData = {
    id: "projects",
    $lightbg: true,
    $lighttext: true,
    $lighttextDesc: false,
    title: "Kädenjälki töissäni",

    project: "Kuvitus",
    projectText: "kuvittajat.fi",
    projectUrl: "https://kuvittajat.fi/",
    topline: "Verkkosivu",
    headline: "Into-digital",

    projectTwo: "Laki",
    projectTextTwo: "procope.fi",
    projectUrlTwo: "https://procope.fi/",
    toplineTwo: "Verkkosivu",
    headlineTwo: "Into-digital",

    projectThree: "Hyväntekeväisyys",
    projectTextThree: "plan.fi",
    projectUrlThree: "https://plan.fi/",
    toplineThree: "Verkkokauppa",
    headlineThree: "Into-digital",

    projectFour: "Lily58",
    toplineFour: "Elektroniikka",
    projectUrlFour: "https://github.com/Laaloz/Lilykeebs58-ice-cream",
    headlineFour: "DIY-projekti",

    buttonLabel: "Projektit",
    $imgstart: false,
    img: `${picIllustrator}`,
    imgTwo: `${picLaw}`,
    imgThree: `${picCharity}`,
    imgFour: `${picLily}`,
    alt: "projects",
    dark: true,
    primary: true,
    darkText: true,
};